<template>
    <v-container class="px-0">
        <jumanjiManager
            v-if="canManageGames"
            :game-id="gameId"
            :teams_count="teams_count"
            :users-actives="usersActives"
            @updateUsersActives="updateUsersActives"
            @restartGame="restartGame"
        />

        <div
            class="mb-4 pb-0"
        >
            <p
                v-if="!canViewCards"
                style="text-align: center"
            >
                <span v-if="team.id">{{ $t('games.team') }}: <strong>{{ team.id }}</strong>, {{ $t('games.gamer') }}: <strong>{{ getUser.name }}</strong></span>

                <span
                    v-else
                    v-text="$t('games.notAvailableForYou')"
                />
            </p>
            <div
                class="d-flex justify-center flex-wrap"
                style="min-height: 400px"
                v-if="showCard"
            >
                <v-fab-transition>
                    <v-img
                        v-if="card && card.image"
                        :src="getImagePath(card.image)"
                        height="400px"
                        max-width="300px"
                        contain
                    />
                </v-fab-transition>

                <div v-if="card && card.answer_ok" style="max-width: 750px">
                    <div class="text-small mt-10" v-text="$t('admin.cards.answer_ok') + ':'"/>
                    <span  v-html="card.answer_ok"/>
                </div>
            </div>

            <div style="display: flex; justify-content: center" v-if="canViewCards">
                <v-switch
                    v-model="showCard"
                    class="px-8"
                    inset
                    :label="$t('games.actions.showReceivedCard')"
                />
            </div>
        </div>
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import { mapGetters, mapActions } from "vuex";
import { debounce } from "debounce";
import NoSleep from 'nosleep.js';

const KEY_INTERVAL = 'jumanji-cards-interval';
export default {
    name: 'JumanjiComponnent',
    components: {
        jumanjiManager: () => import('@/components/games/jumanji/jumanjiManager')
    },
    props: {
        gameId: {
            type: Number,
            required: true
        },
        teams_count: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            urlMain: '/games/jumanji/getMyCurrentState/' + this.gameId,
            card: {
                id: '',
                image: ''
            },
            team: {
                id: ''
            },
            showCard: true,
            usersActives: {}, // Map with key userIds and states like values: 2->ONLINE, 1->previously ONLINE and 0 or don't exist mean OFFLINE
            noSleepScreen: new NoSleep()
        }
    },
    computed: {
        ...mapGetters('cache', ['getMemoryInfo']),
        ...mapGetters('permissions', ['canManageGames', 'canViewCards']),
        ...mapGetters(['getUser', 'isAdmin'])
    },
    async created() {
        await this.fetchData();

        this.configureSocket();

        this.checkUnlockScreen();
        this.checkSocket();
    },
    async mounted() {
        await this.noSleepScreen?.enable();
    },
    beforeDestroy() {
        const lastTimeInterval = this.getIntervalId()
        if (lastTimeInterval) {
            window.clearInterval(lastTimeInterval);
            this.setOnMemoryInfo({ key: KEY_INTERVAL, value: null });
        }

        // ********** LEAVING THE SOCKET CHANELS *******
        const gameId = this.gameId;
        const teamId = this.team.id ?? '_';
        const userId = this.getUser.id ?? '_';

        let channelsToLeave = [];
        if (this.canViewCards) {
            channelsToLeave = [
                { channel: `cards.${gameId}._._`, event: 'CardGameSent' },
                { channel: `cards.admin.${gameId}`, events: ['CardGameSent', 'CardTeamSent', 'CardSent'] }
            ];
        } else {
            channelsToLeave = [
                { channel: `cards.${gameId}._._`, event: 'CardGameSent' },
                { channel: `cards.${gameId}.${teamId}._`, event: 'CardTeamSent' },
                { channel: `cards.${gameId}.${teamId}.${userId}`, event: 'CardSent' }
            ];
        }

        for (const channel of channelsToLeave) {
            window.Echo.leave(channel.channel)
        }

        //* ****** DISABLE SCREEN DONT SLEEP FUNCTION
        this.noSleepScreen.disable();
    },
    methods: {

        async fetchData() {
            try {
                const data = await axios
                    .get(this.urlMain);

                this.initialize(data.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize(data) {
            this.receiveCard(data.cardActive);
            this.team.id = data.team;
        },
        getImagePath(image) {
            return image ? process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=' + image : '';
        },
        receiveCard(card) {
            this.card = card;
        },
        setUserState(user, state) {
            const userId = user.id + '';
            const previousState = this.usersActives[userId];

            let stateNew = 2; // ONLINE
            // 1 mean previously ONLINE and 0 OFFLINE
            if (!state) {
                stateNew = previousState ? 1 : 0;
            }

            this.$set(this.usersActives, userId, stateNew);
        },
        updateUsersActives(users) {
            users.forEach((userId) => {
                const previousState = this.usersActives[userId];
                const stateNew = previousState || 1;
                this.$set(this.usersActives, userId, stateNew);
            });
        },
        restartGame() {
            for (const userId in this.usersActives) {
                const previousState = this.usersActives[userId];
                const stateNew = previousState === 1 ? 0 : previousState;
                this.$set(this.usersActives, userId, stateNew);
            }
        },
        configureSocket() {
            const token = this.$store.getters.getToken;

            try {

                window.Echo.connector.options.auth.headers.authorization = 'Bearer ' + token;
                // window.Echo.connector.pusher.config.auth.headers.authorization = 'Bearer ' + token;
                //window.Echo.connector.options.bearerToken = 'Bearer ' + token;
            }catch (e) {
                this.$store.dispatch('snackbar/error', 'Existen problemas comunicandose con el servidor.');
                // return;
            }

            // window.Echo.connector.pusher.config.auth.headers['Content-Type'] = 'application/vnd.api+json';
            // window.Echo.connector.pusher.config.auth.headers.Accept = 'application/vnd.api+json';

            const gameId = this.gameId;
            const teamId = this.team.id ?? '_';
            const userId = this.getUser.id ?? '_';

            if (this.canViewCards) {
                const channel = { channel: `cards.admin.${gameId}`, events: ['CardAdminSent'] };
                // listen all the cards on the admin channel
                window.Echo.join(channel.channel)
                    .listen(channel.events[0], (e) => {
                        // console.log(channel.events[0], e)
                        this.receiveCard(e?.card?.card);
                    })
                    /*.listen(channel.events[1], (e) => {
                        // console.log(channel.events[1], e)
                        this.receiveCard(e?.card?.card);
                    })
                    .listen(channel.events[2], (e) => {
                        // console.log(channel.events[2], e)
                        this.receiveCard(e?.card?.card);
                    })*/;

                const channelPresence = { channel: `cards.${gameId}._._`, event: 'CardGameSent' };
                // listen the users presences on game channel game._._._
                window.Echo.join(channelPresence.channel)
                    .here(users => {
                        // console.log("here ", users)
                        users.forEach(user => { this.setUserState(user, true); });
                    })
                    .joining(user => {
                        // console.log("joining ", user)
                        this.setUserState(user, true);
                    })
                    .leaving(user => {
                        // console.log("leaving ", user)
                        this.setUserState(user, false);
                    })
                    .listenForWhisper('typing', e => {
                        // console.log("listenForWhisper ", e)
                    });
            } else if (this.team.id) {
                const channelsToListen = [
                    { channel: `cards.${gameId}._._`, event: 'CardGameSent' },
                    { channel: `cards.${gameId}.${teamId}._`, event: 'CardTeamSent' },
                    { channel: `cards.${gameId}.${teamId}.${userId}`, event: 'CardSent' }
                ];

                // listen of the 3 channel (Game, team, and user) Example: cards.1.1._
                for (const channel of channelsToListen) {
                    window.Echo.join(channel.channel)
                        .listen(channel.event, (e) => {
                            // console.log(channel.event, e)
                            this.receiveCard(e?.card?.card);
                        });
                }
            }
        },
        updateMyStatus: debounce(async function (e) {
            // alert('updateMyStatus');
            //console.log('********** updateMyStatus **********')
            await this.fetchData();
        }, 5000),
        checkUnlockScreen() {
            // ********** FOR CHECK MOBILE UNLOCK SCREEN OR
            // BROWSER EXECUTE AGAIN JAVASCRIPT AFTER 4 SECONDS ********
            var timestamp = new Date().getTime();
            function checkResume() {
                var current = new Date().getTime();

                if (current - timestamp > 3000) {
                    const event = new Event("resume", { bubbles: true, cancelable: true });
                    document.dispatchEvent(event);
                }
                timestamp = current;
            }

            const lastTimeInterval = this.getIntervalId();
            if (lastTimeInterval) {
                window.clearInterval(lastTimeInterval);
            }

            const lastTimeIntervalNew = window.setInterval(checkResume, 1000);
            this.setOnMemoryInfo({ key: KEY_INTERVAL, value: lastTimeIntervalNew });

            const funcExecute = function () {
                try {
                    this.updateMyStatus && this.updateMyStatus();
                } catch (e) {
                    console.log('Resume, dont found updateMyStatus')
                }
            }.bind(this);

            addEventListener("resume", funcExecute);
        },
        checkSocket() {
            // **************** LISTEN PUSHER STATE CHANGE *********
            window.Echo.connector.pusher.connection.bind("state_change", (states) => {
                // states = {previous: 'oldState', current: 'newState'}
                switch (states.current) {
                // case 'disconnected':
                case 'failed':
                case 'unavailable':
                    this.updateMyStatus();
                    break;
                case 'connecting':
                    if (states.previous === 'connected') { this.updateMyStatus(); }
                    break;
                case 'connected':
                    if (states.previous === 'connecting') { this.updateMyStatus(); }
                    break;
                }

                console.log('******** states', states)
            });
        },
        getIntervalId() {
            const memoryInfo = this.getMemoryInfo;
            return memoryInfo && memoryInfo[KEY_INTERVAL];
        },
        ...mapActions('cache', ['setOnMemoryInfo'])
    }
}
</script>

<style scoped>

</style>
